import { Container } from "react-bootstrap";
import AboutCleaningServices from "./AboutCleaningServices";
import CleaningServices from "./CleaningServices";
import Bg from '../assets/image.png'
import Background from "../component/Background";

export default function() {
    return (
        <Background background={Bg}>
            <AboutCleaningServices />
            <CleaningServices />
        </Background>
    )
}