import dotenv from 'dotenv'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter,
  } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { App } from "./App";

console.log(process.env.NODE_ENV)
dotenv.config({path: `.env.${process.env.NODE_ENV}`})

const container = document.getElementById("app");
const root = createRoot(container)
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);