
import Header from './Header'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigate,
  } from "react-router-dom";
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import { Col, Container, Row } from 'react-bootstrap';
import Footer from './Footer';
import CleaningServices from './CleaningServices';
import LawnServices from './LawnServices';
import ScrollToTop from './component/ScrollToTop';

export function App() {
    var location = useLocation()
    var navigate = useNavigate()

    console.log(location)
    return (
        <div className='bg-body-tertiary'>
            <ScrollToTop />
            <Header />
            {
                location.pathname != "/contact" ?
            <div className='bg-dark text-white'>
                <Container className='bg-blue py-3'>
                    <Row>
                        <Col lg={8}>
                            <h5>About Us</h5>
                            <p>ECOGLOW is your eco-friendly cleaning and landscaping solution. Founded by the Tauraoi family, we're dedicated to providing top-notch services while protecting the environment. With us, you get quality and sustainability in one package. Join us in creating a greener world—one clean lawn at a time.</p>
                        </Col>
                        <Col lg={4} className='my-auto'>
                            <button  type="button" onClick={() => {
                                navigate('/contact')
                            }} class="btn btn-primary btn-lg float-end text-end">Get A Free Quote</button>
                        </Col>
                    </Row>
                    
                </Container>
            </div> : <></>
            }
            <div className=''>
                <Routes>
                    <Route path='/' Component={AboutUs} />
                    <Route path='/contactus' Component={ContactUs} />
                    <Route path='/cleaning' Component={CleaningServices}/>
                    <Route path='/contact' Component={ContactUs} />
                    <Route path='/landscaping' Component={LawnServices} />
                </Routes>
            </div>
            <Footer />
        </div>
    )
}
