export default function({children, background}) {
    return (
        <div className="py-5" style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: 'center', /* Center the image */
            backgroundRepeat: 'no-repeat', /* Do not repeat the image */
            backgroundSize: 'cover',
            backgroundColor: 'black',
        }}>{children}</div>
    )
}