import { Carousel, Container, Image } from "react-bootstrap";

export default function() {
    return (
        <div className="text-white">
        <Container>
            <h6 className="pb-3">About Ecoglow Cleaning's Cleaning Services</h6>
            <h3>Cleaning Services</h3>
            <p>
                EcoGlow Limited is the cleaning company of choice for all of New Zealand’s residential and commercial 
                cleaning requirements. From homes to offices, EcoGlow Limited does it all. We are the leading home and 
                commercial cleaning service, offering reliable and affordable services.
            </p>
            <p>
                We cater to both home and business owners, providing specialist cleaning services to ensure your property
                is clean from top to bottom, inside and out. Our home and office cleaning packages are truly the signature 
                services of EcoGlow Limited. We have extensive experience in providing both regular and one-off cleaning for 
                your home or business property.
            </p>
            <p>
                By choosing EcoGlow Limited, you are not only ensuring a pristine environment but also contributing to a safer, 
                greener planet. Our commitment to using eco-friendly chemicals, equipment, and tools underscores our dedication 
                to sustainability and the well-being of our community.
            </p>

            <div>

            </div>
        </Container>
        </div>
        
    )
}