var cleaningServices = [
    'Home Cleaning',
    'Office & Commercial Cleaning',
    'Disinfection Service',
    'End of Lease Cleaning',
    'Oven Cleaning'
]

var lawnServices = [
    'Lawn Mowing',
    'Trimming & Pruning',
    'Garden Landscaping',
    'Gutter Cleaning',
    'Rubbish Removal',
    'Design',
    'Earthworks & Site Clearing',
    'Timber',
    'Concrete',
    'Masonry & Paving',
    'Aluminium & Steel',
    'Irrigation & Lighting'
]

export const getServices = (serviceType) => {

    if (serviceType == 'Cleaning Services') {
        return cleaningServices;
    }

    return lawnServices; 
}

