import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from './assets/logo2.png';
import { LinkContainer } from 'react-router-bootstrap';

export default function() {
    return (
        <Navbar sticky="top" expand="lg" bg="white" className="bg-success-subtle">
            <Container>
                <Navbar.Brand href="/"><img src={Logo} width={150} alt="Logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                    <LinkContainer to="/">
                        <Nav.Link href='/'>
                        Home
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="cleaning">
                        <Nav.Link href='/cleaning'>
                        Cleaning Services
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="landscaping">
                        <Nav.Link href='/landscaping'>
                        Landscaping Services
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="contact">
                        <Nav.Link href='#'>
                            Contact Us
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}