import { useEffect, useState } from "react";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";

var servicesContent = ['Home Cleaning', 'Office & Commercial Cleaning', 'Disinfection Service', 'End of Lease Cleaning', 'Oven Cleaning']
var detailsContent = [
    'At EcoGlow Limited, we offer flexible home cleaning services tailored to fit your schedule. Choose from daily, weekly, fortnightly, or monthly cleaning options. Our reliable and carefully vetted cleaners are dedicated to maintaining your property’s cleanliness and order. We understand that every home is unique, so our team takes the time to understand your specific needs, ensuring a thorough and personalized cleaning experience every time.',
    'EcoGlow Limited provides bespoke cleaning services for various business types, including offices, hospitality venues, and retail spaces. Our team works with you to develop a cleaning plan that fits seamlessly into your business operations. Whether you require cleaning during business hours, before or after hours, or even on weekends, we ensure minimal disruption to your work environment. Our professional cleaners use eco-friendly products and advanced techniques to maintain a pristine and healthy workspace.',
    'Enhance the safety and hygiene of your spaces with EcoGlow Limited’s specialized disinfection services. We utilize state-of-the-art methods and environmentally safe disinfectants to effectively eliminate harmful germs, viruses, and bacteria. Our comprehensive approach ensures that every surface is meticulously sanitized, providing a healthier environment for you, your family, or your employees. Trust us to deliver peace of mind through our rigorous and effective disinfection processes.',
    'Make your move stress-free with EcoGlow Limited’s end-of-lease cleaning service. Our expert team pays close attention to every detail, ensuring that your property meets or exceeds the landlord’s cleanliness standards. We cover all aspects of the cleaning process, from deep cleaning carpets and floors to sanitizing kitchens and bathrooms. With our thorough service, you can rest assured that your space will be spotless, facilitating a smooth transition and helping you secure your bond return.',
    'Experience the exceptional results of EcoGlow Limited’s professional oven cleaning services. Our meticulous cleaning process removes grease, burnt-on food, and odors, restoring your oven to its original shine and efficiency. We use eco-friendly cleaning agents that are tough on grime but gentle on the environment. By choosing our service, you can enjoy a clean, hygienic oven that enhances your cooking experience and prolongs the lifespan of your appliance.'
];
var images = [
    '',
    '',
    '',
    ''
]

export default function() {
    const [services, setServices] = useState([])
    const [details, setDetails] = useState([])

    useEffect(() => {
    console.log("detailsContent")

        setServices(servicesContent)
        setDetails(detailsContent)
    }, [])


    return (
        <Container className="opacity-100">
            <h2 className="text-white">Our Services</h2>
            <Row>
                {
                    services.map((service, index) => {
                        return (
                            <Col lg={4}>
                                <Card className="mt-5">
                                    <Card.Body>
                                        <Card.Title style={{color: '#00C1FB'}}>{service}</Card.Title>
                                        <Card.Text>{details[index]}</Card.Text>
                                    </Card.Body>
                                    <Card.Body>
                                        <Card.Link href="/contact"><Button variant="primary">Get A FREE Quote</Button></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}