import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Bg from './assets/landscaping.png'
import Background from "./component/Background";

export const lawnServicesContent = ["Lawn Mowing", "Trimming & Pruning", "Garden Landscaping", "Gutter Cleaning", "Rubbish Removal", "Design", "Earthworks & Site Clearing", "Timber", "Concrete", "Masonry & Paving", "Aluminium & Steel", "Irrigation & Lighting"]
const detailsContent = [
    "EcoGlow Limited offers professional lawn mowing services to keep your lawn looking pristine and well-maintained. Our team uses top-of-the-line, eco-friendly equipment to ensure a clean, even cut that promotes healthy grass growth. Whether you need a one-time service or regular maintenance, we tailor our lawn mowing schedules to meet your specific needs and keep your outdoor space looking its best year-round.",
    "Proper trimming and pruning are essential for the health and appearance of your plants and trees. EcoGlow Limited provides expert trimming and pruning services to ensure your greenery is well-shaped, healthy, and safe. Our team follows best practices to promote optimal growth, prevent disease, and enhance the aesthetic appeal of your landscape.",
    "Transform your outdoor space with EcoGlow Limited's comprehensive garden landscaping services. We offer a full range of landscaping solutions, from design and planning to installation and maintenance. Our team works closely with you to create a personalized landscape that reflects your style and meets your functional needs. Using sustainable materials and practices, we ensure your garden is both beautiful and environmentally friendly.",
    "Keep your gutters free of debris and functioning properly with EcoGlow Limited’s gutter cleaning services. Our team ensures that your gutters and downspouts are clear of leaves, dirt, and other blockages that can cause water damage to your property. Regular gutter cleaning helps maintain the integrity of your home or business and prevents costly repairs down the line.",
    "EcoGlow Limited provides efficient and eco-friendly rubbish removal services. Whether you need to clear out garden waste, household junk, or commercial debris, our team handles the job with professionalism and care. We ensure that all waste is disposed of responsibly, recycling materials whenever possible to minimize environmental impact.",
    "Our design services at EcoGlow Limited help you envision and plan your ideal outdoor space. We collaborate with you to create detailed, customized landscape designs that incorporate your preferences and the unique characteristics of your property. Our designs prioritize sustainability and beauty, ensuring your outdoor space is both functional and aesthetically pleasing.",
    "EcoGlow Limited offers earthworks and site clearing services to prepare your property for construction, landscaping, or other projects. Our experienced team uses modern, eco-friendly equipment to handle excavation, grading, and debris removal efficiently and safely. We ensure your site is ready for the next phase of your project, adhering to all environmental regulations.",
    "Our timber services include the supply and installation of high-quality timber for various landscaping and construction projects. From decking and fencing to garden beds and pergolas, EcoGlow Limited sources sustainable timber to enhance your outdoor spaces. Our skilled craftsmen ensure precise installation, delivering durable and aesthetically pleasing results.",
    "EcoGlow Limited provides professional concrete services for a variety of applications, including driveways, patios, pathways, and more. Our team uses eco-friendly techniques and materials to create strong, long-lasting concrete structures that enhance the functionality and appearance of your property. We ensure precise work and a smooth finish, tailored to your specific requirements.",
    "Enhance your outdoor areas with EcoGlow Limited’s masonry and paving services. We specialize in the design and installation of stone, brick, and paver features that add elegance and durability to your landscape. Whether you need a new walkway, patio, or retaining wall, our expert masons deliver high-quality craftsmanship that stands the test of time.",
    "Our aluminium and steel services include the fabrication and installation of custom metal structures for your landscape. EcoGlow Limited offers a range of solutions, from garden trellises and railings to gates and decorative elements. We use sustainable materials and techniques to create durable, stylish features that complement your outdoor space.",
    "Optimize the health and beauty of your landscape with our irrigation and lighting services. EcoGlow Limited designs and installs efficient irrigation systems that ensure your plants receive the right amount of water. Our outdoor lighting solutions highlight the best features of your landscape, providing safety and ambiance. Both systems are designed with sustainability in mind, using energy-efficient and water-saving technologies."
]

export default function() {
    const [lawnServices, setlLawnServices] = useState([])
    const [details, setDetails] = useState([])

    useEffect(() => {
        setlLawnServices(lawnServicesContent)
        setDetails(detailsContent)
    }, [])

    return (
        <Background background={Bg}>
            <Container className="py-2 text-white">
            <h6 className="pb-3">Your Premier Eco-Friendly Landscape and Lawn Care Experts</h6>
            <h3>Landscaping Services</h3>
            <p>
            EcoGlow Limited offers unparalleled landscape design and lawn care services across Auckland. 
            From design to decking, planting to paving, we handle every aspect of your project with precision 
            nd care. As your single point of contact, we ensure seamless coordination of our dedicated team and 
            trusted contractors, transforming your vision into reality.
            </p>
            <p>
            As an eco-friendly service provider, we prioritize sustainability in all our operations, using 
            environmentally safe materials and practices. Our commitment to excellence guarantees a stress-free 
            experience for our clients, team, and subcontractors, marked by clear communication and top-quality 
            workmanship. Choose EcoGlow Limited for a greener, more beautiful environment.
            </p>
                <h2>Our Services</h2>
                <Row>
                    {
                        lawnServices.map((service, index) => {
                            return (
                                <Col lg={4}>
                                    <Card className="mt-5">
                                        <Card.Body>
                                            <Card.Title style={{color: '#00C1FB'}}>{service}</Card.Title>
                                            <Card.Text>{details[index]}</Card.Text>
                                        </Card.Body>
                                        <Card.Body>
                                            <Card.Link href="/contact"><Button variant="primary">Get A FREE Quote</Button></Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </Background>
    )
}