import { Col, Row, Container } from "react-bootstrap";
import Bg from './assets/home2.jpg'
import Background from "./component/Background";
export default function() {
    return (
        <Background background={Bg}>
            <Container fluid >
                <Container className="py-5 text-white">
                <Row>
                    <Col lg={12} className="bg-red">
                    <h4 className="text-white">Your Premier Eco-Friendly Cleaning, Lawn Care, and Landscaping Service Provider in Auckland City</h4>
                    <p>
                        Welcome to EcoGlow, where we redefine excellence in cleaning, lawn care, and landscaping services across Auckland City. With a strong commitment to sustainability and environmental responsibility, EcoGlow is your trusted partner for all your residential and commercial property needs.
                    </p>
                    <br />
                    <h4 className="text-white">Exceptional Eco-Friendly Cleaning Services</h4>
                    <p>
                        Our residential and commercial cleaning services are not only tailored to meet the highest standards of cleanliness and hygiene but are also conducted with eco-friendly chemicals, equipment, and tools. This commitment to sustainability ensures that your space is not only clean but also safe for your family and the environment. We work closely with you to create a customized cleaning plan that meets your specific requirements while minimizing our environmental footprint.
                    </p>
                    <br />

                    <h4 className="text-white">Expert Eco-Friendly Lawn Care Solutions</h4>
                    <p>
                        A lush, green lawn enhances the beauty of your property. Our lawn care services are designed to keep your lawn healthy and vibrant throughout the year, using eco-friendly practices and products. From mowing to fertilizing, our experienced team ensures that your lawn is maintained to the highest standards while protecting the environment.
                    </p>
                    <br />

                    <h4 className="text-white">Creative Eco-Friendly Landscaping Services</h4>
                    <p>Transform your outdoor space into a beautiful and sustainable area with our eco-friendly landscaping services. Whether you're looking to create a new garden, install a lawn, or add hardscaping features, our team of landscaping experts uses eco-friendly materials and techniques to turn your vision into reality. We take pride in our attention to detail and creative approach to landscaping, ensuring that your outdoor space is both stunning and environmentally friendly.
                    </p>
                    <br />

                    <h4 className="text-white">Seamless Booking Process</h4>
                    <p>
                        Booking a service with EcoGlow is easy and convenient. Simply contact us to schedule a consultation, and our team will work with you to understand your needs and provide a detailed quote. Once you're happy with the plan, we'll schedule a date and time for the service to be carried out. Our team will arrive promptly with all the necessary eco-friendly equipment to complete the job efficiently and to your satisfaction.
                    </p>
                    <br />

                    <h4 className="text-white">Trust and Reliability</h4>
                    <p>
                        At EcoGlow, we understand the importance of trust and reliability when it comes to your property and the environment. That's why all our team members are fully trained, insured, and committed to using eco-friendly practices in all our services. You can rely on us to deliver exceptional results while protecting your family and the environment.
                    </p>
                    <br />

                    <h4 className="text-white">Experience the EcoGlow Difference</h4>
                    <p>
                        With years of experience serving residential and commercial clients in Auckland City, EcoGlow is your go-to partner for all your eco-friendly cleaning, lawn care, and landscaping needs. Request a free quote today and experience the EcoGlow difference for yourself.
                    </p>
                        
                    </Col>
                    <Col lg={3}></Col>
                </Row>
                </Container>
            </Container>
        </Background>
    )
}