import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getServices } from './util'
import { useEffect, useRef, useState } from "react";
import * as emailjs from '@emailjs/browser';
import Background from "./component/Background";
import Bg from './assets/contactus.jpg'
import ReCAPTCHA from 'react-google-recaptcha'

export default function() {

    const [serviceTypes, setServceTypes] = useState(["Cleaning Services", "Landscaping Services"])
    const [services, setServices] = useState([])
    const [selectedService, setSelectedService] = useState()
    const [inputEmail, setEmail] = useState()
    const recaptcha = useRef()

    useEffect(() => {
        document.getElementById('serviceType').selectedIndex = -1;
    }, [])

    const getContent = (name) => {
        var services = getServices(name)
        setServices(services)
    }

    const sendEmail = (e) => {
        e.preventDefault()
        
        var service = document.getElementById('serviceType').value
        if (selectedService && !service) {
            alert('Please select service type')
            return
        }
                
        if (!process.env.serviceid) {
            console.log('Service id not found')
            return;
        }

        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
            return
        } 
        
        var message = document.getElementById('message').value
        var email = document.getElementById('email').value
        
        const params = {
            service,
            message,
            email,
            "g-recaptcha-response": captchaValue
        }

        console.log(params)
        emailjs
        .send(process.env.serviceid, process.env.templateid, params, {
          publicKey: process.env.pubkey,
        })
        .then(
          () => {
            alert('Email Sent')
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );

    }

    return (
        <Background background={Bg}>

        <Container className="text-white">
            <h1 style={{color: '#00C1FB'}} className="pb-4">Get in Touch with Us</h1>
            <Row className="pb-5">
                <Col lg={6} className="bg-red">

                    <p>
                        At EcoGlow, our commitment to excellence and eco-friendly practices sets us apart in the cleaning industry. 
                        Our dedicated professionals are strategically located throughout Auckland City, ensuring that no matter where 
                        you are, a trusted EcoGlow expert is always within reach. We take pride in equipping each team with the latest 
                        environmentally friendly tools and products, enabling us to tackle any cleaning challenge with precision and care. 
                        Experience the EcoGlow difference today and find your nearest location to enjoy unparalleled service quality.
                    </p>
                    <br />

                    <h4 className="text-body-tertiary">Professional Service Excellence</h4>
                    <p>
                        Reach out to EcoGlow today and discover how we can transform your space with our efficient, reliable, and eco-conscious
                         cleaning solutions. Our team is composed of highly trained and vetted professionals who are passionate about 
                         delivering exceptional results while minimizing our environmental footprint. Whether it's a residential, commercial, 
                         or specialized cleaning task, we approach each project with the highest standards of professionalism and attention 
                         to detail.
                    </p>
                    <br />
                    <h4 className="text-body-tertiary">Book Now</h4>
                    <p>
                        For a seamless and personalized experience, use our booking form to request a quote. Our experts are on standby 
                        throughout Auckland City, ready to provide you with an accurate estimate tailored to your specific needs. 
                        At EcoGlow, we understand that every space is unique, and we are committed to offering bespoke cleaning services 
                        that meet and exceed your expectations. Contact us today to schedule a consultation and take the first step towards a cleaner, greener environment.
                    </p>
                    <br />
                </Col>
                
                <Col lg={4} className="mx-auto">
                <Form id="form" class="row g-3" onSubmit={sendEmail}>
                    <div class="col-md-12">
                        <label for="inputEmail4" class="form-label">Email</label>
                        <input type="email" class="form-control" id="email" name="email" value={inputEmail} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div class="col-md-12">
                        <label for="inputState" class="form-label">Select Service Type</label>
                        <select id="serviceType" class="form-select" onChange={(e) => {
                            getContent(e.target.value)
                        }}>
                            {
                                serviceTypes.map((item, index) => index == 0 ? <option selected>{item}</option> : <option>{item}</option>)
                            }
                        </select>
                    </div>
                    {
                        services && services.length > 0 ? <div class="col-md-12">
                        <label for="inputState" class="form-label">Select Service</label>
                        <select id="service" class="form-select" name="service" defaultValue="">
                            {
                                services.map(item => <option>{item}</option>)
                            }
                        </select>
                    </div> : ''
                    }
                    
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                        <textarea class="form-control" id="message" rows="10" name="message"></textarea>
                    </div>
                    <Button variant="primary" type="submit"> 
                        Get A FREE Quote
                    </Button> 
                    </Form>
                    <br />
                    <ReCAPTCHA ref={recaptcha} sitekey={process.env.sitekey} />
                    <br />
                </Col>
            </Row>
        </Container>
        </Background>

    )
}