import { Col, Container, Row } from "react-bootstrap";
import Logo from './assets/logo2.png';

export default function() {
    return (
        <div className="text-white bg-dark">
            <div className="bg-success-subtle text-black">
                <Container>
                    <Row>
                        <Col lg={3} className="bg-red mt-0 pt-0">
                            <img src={Logo} width={200} alt="Logo" />
                        </Col>
                        <Col lg={5} className="bg-red my-auto">
                            <p><b style={{color:'#00C1FB'}}>Phone</b> - 0211740810</p>
                            <p><b style={{color:'#00C1FB'}}>Email</b> - pitelytauraoi@ecoglow.site</p>
                            <p><b style={{color:'#00C1FB'}}>Service Location</b> - Manukau, South Auckland, North Shore, East Auckland, West Auckland, Central Auckland</p>
                        </Col>
                        <Col lg={4} className="bg-red my-auto">
                            <p>Monday-Friday: 9am - 5pm</p>
                            <p>Saturday: 9am - 12pm</p>
                            <p>Sunday: Closed.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <br />
            <div className="px-auto p-3">
                <Container>
                <p >
                    COPYRIGHT {new Date().getFullYear()}. ECOGLOW LIMITED. ALL RIGHTS RESERVED
                </p>
                </Container>
            </div>
        </div>
    )
}